<template>
    <div 
        ref="floatMenu" 
        :class="[
            mouse_clicked ? 'float-down' : '',
            'float-menu bg-primary w-14 h-14 text-black border-black border'
        ]" 
        :style="elementStyle"
    >
        <div class="flex justify-center content-center h-full">
        <unicon class="self-center" name="shopping-cart" fill="#000000"/>
        <span class="text-xs absolute right-2 top-2" v-html="cartProductsLength"/>
        </div>
    </div>
</template>


<script>
export default {
    props: ['open', 'closed'],
    name: 'AssistiveTouch',
    data() {
        return {
            parent: null,
            w: null,
            h: null,
            xoffset: null,
            yoffset: null,
            headerThreshold: 0.2,
            mouse_over: false,
            mouse_clicked: false,
            drag: false,
            drag_count: 0,
            elementStyle: {
                top: null,
                left: null,
            },
            widthBox: null,
            heightBox: null,    
            pw: null,
            ph: null,
            snapback_interval: null,
        };
    },
    computed: {
        cartProductsLength() {
            return this.$store.getters.numberOfProductsInCart
        }
    },
    mounted() {
        this.parent = this.getBoundingParent();

        this.w = this.$el.clientWidth
        this.h = this.$el.clientHeight

        this.xoffset = Math.round(this.w / 2);
        this.yoffset = Math.round(this.h / 2);
        
        [this.pw, this.ph] = this.getParentWidthHeight();

        this.widthBox = this.box(0, this.pw - this.$el.clientWidth);
        this.heightBox = this.box(0, this.ph - this.$el.clientHeight);

        this.$el.addEventListener('mouseover', this.onMouseOver);
        this.$el.addEventListener('mouseleave', this.onMouseLeave);
        this.$el.addEventListener('mousedown', this.onMouseDown)
        this.$el.addEventListener('mouseup',   this.onMouseUp)
        this.$el.addEventListener('mousemove', this.onMouseMove);

        this.$el.addEventListener('touchstart', this.onTouchStart, false);
        this.$el.addEventListener('touchend', this.onTouchEnd, false);
        this.$el.addEventListener('touchmove', this.onTouchMove, false);

        window.addEventListener('resize', this.onResize);
    },
    destroyed() {
        this.$el.removeEventListener('mouseover', this.onMouseOver);
        this.$el.removeEventListener('mouseleave', this.onMouseLeave);
        this.$el.removeEventListener('mousedown', this.onMouseDown)
        this.$el.removeEventListener('mouseup',   this.onMouseUp)
        this.$el.removeEventListener('mousemove', this.onMouseMove);

        this.$el.removeEventListener('touchstart', this.onTouchStart, false);
        this.$el.removeEventListener('touchend', this.onTouchEnd, false);
        this.$el.removeEventListener('touchmove', this.onTouchMove, false);

        window.removeEventListener('resize', this.onResize);

        clearInterval(this.snapback_interval);
    },
    methods: {
        onMouseOver(e) {
            e.preventDefault();
            this.mouse_over = true;
        },
        onMouseLeave(e) {
            e.preventDefault();
            this.mouse_over = false;
        },
        onMouseDown(e) {
            e.preventDefault();
            this.drag = false;
            this.mouse_clicked = true;
        },
        onMouseUp(e) {
            e.preventDefault();
            this.mouse_clicked = false;
            this.drag_count = 0;

            let direction = this.calcMinDirection();
            this.snapback_interval = setInterval(this.snapback, 10, direction);
        },
        onMouseMove(e) {
            e.preventDefault();
            this.drag = true;

            if (this.mouse_clicked) {
                if (e) {
                    this.drag_count++;
                    if (this.drag_count >= 10) {
                        this.drag = true;
                    } else {
                        this.drag = false;
                    }
                }
                this.updatePositionOnDrag(e.clientX, e.clientY);
            }
        },
        onTouchStart() {
            // e.preventDefault();
            this.drag = false;
            this.mouse_clicked = true;
        },
        onTouchMove(e) {
            // e.preventDefault();
            this.drag = true;

            if (this.mouse_clicked) {
                if (e) {
                    this.drag_count++;
                    if (this.drag_count >= 10) {
                        this.drag = true;
                    } else {
                        this.drag = false;
                    }
                }
                this.updatePositionOnDrag(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
            }
        },
        onTouchEnd() {
            // e.preventDefault()
            this.mouse_clicked = false;

            let direction = this.calcMinDirection();
            this.snapback_interval = setInterval(this.snapback, 10, direction);
        },
        onResize() {
            let w, h;
            [w, h] = [this.w, this.h];

            [this.pw, this.ph] = this.getParentWidthHeight();

            this.widthBox = this.box(0, this.pw - w);
            this.heightBox = this.box(0, this.ph - h);

            let [x, y] = this.getCoords();
            let [parentWidth, parentHeight] = this.getParentWidthHeight();

            let direction = this.calcMinDirection();
            
            if (direction == 'right') {
                this.elementStyle.left = (parentWidth - w) + 'px'
            }

            if (direction == 'left') {
                this.elementStyle.left = 0 + 'px'
            }

            if (direction == 'top') {
                if (parentWidth <= x + w) {
                    this.elementStyle.left = (parentWidth - w) + 'px'
                } else {
                    this.elementStyle.top = 0 + 'px'
                }
            }

            if (direction == 'bottom') {
                if (parentWidth <= x + w) {
                    this.elementStyle.left = (parentWidth - w) + 'px'
                } else {
                    this.elementStyle.top = (parentHeight - h) + 'px'
                }
            }

        },
        updatePositionOnDrag(x, y) {
            this.setPosition(...this.boundingBox(x - this.xoffset, y - this.yoffset))
        },
        setPosition(x, y) {
            this.elementStyle.left = x + 'px';
            this.elementStyle.top = y + 'px';
        },
        box(lower, upper) {
            return (x) => { return Math.min(Math.max(x, lower), upper) }
        },
        boundingBox(x, y) {
            return [this.widthBox(x), this.heightBox(y)]
        },
        pixelToNum(pixel) {
            let parts = pixel.split('px');
            return parseFloat(parts[0]);
        },
        calcMinDirection() {
            let [w, h] = this.getWidthHeight();
            let [x, y] = this.getCoords();
            let [parentWidth, parentHeight] = this.getParentWidthHeight();
            let headerThreshold = parentHeight * this.headerThreshold;

            let min_dir = 'left';

            if (parentWidth - x < x) {
                min_dir = 'right';
            }

            if (y < headerThreshold) {
                min_dir = 'top';
            }

            if (parentHeight - y < h + headerThreshold) {
                min_dir = 'bottom';
            }

            return min_dir;
        },
        snapback(direction) {
            let w, h;
            [w, h] = [this.w, this.h];

            let [x, y] = this.getCoords();
            let [parentWidth, parentHeight] = this.getParentWidthHeight();

            if ((x <= 0.5 && x >= -0.5)
            || (y <= 0.5 && y >= -0.5 && x + w <= parentWidth - 0.5)
            || (y + h >= parentHeight - 0.5 && y + h <= parentHeight + 0.5)
            || (x + w >= parentWidth - 0.5 && x + w <= parentWidth + 0.5 && y + h <= parentHeight + 0.5)
            || this.mouse_clicked) {
                clearInterval(this.snapback_interval)
            }

            if (direction == 'left') {
                x -= x / 20;
                this.elementStyle.left = x + 'px';
            }

            if (direction == 'right') {
                x -= (x + w - parentWidth) / 20;
                this.elementStyle.left = x + 'px';
            }

            if (direction == 'top') {
                y -= y / 20;
                this.elementStyle.top = y + 'px';

                if ((x + w) >= parentWidth) {
                    x -= (x + w - parentWidth) / 20;
                    this.elementStyle.left = x + 'px';
                }
            }

            if (direction == 'bottom') {
                y -= (y + h - parentHeight) / 20;
                this.elementStyle.top = y + 'px';

                if ((x + w) >= parentWidth) {
                    x -= (x + w - parentWidth) / 20;
                    this.elementStyle.left = x + 'px';
                }
            }
        },
        getWidthHeight() {
            return [this.$el.clientWidth, this.$el.clientHeight];
        },
        pageWidthHeight() {
            return [window.innerWidth, window.innerHeight]
        },
        getParentWidthHeight() {
            if (this.parent === window) {
                return this.pageWidthHeight();
            }
            return [this.parent.clientWidth, this.parent.clientHeight]
        },
        getCoords() {
            return [this.pixelToNum(this.$el.style.left), this.pixelToNum(this.$el.style.top)];
        },
        getBoundingParent() {
            let pe = document.body;
            while (Math.min(pe.clientWidth, pe.clientHeight) <= 0) {
                pe = pe.parentElement
            }
            return pe
        },
    },
}
</script>

<style scoped>
    .float-menu {
        display: block;
        position: fixed;
        z-index: 10000;
    }

    .float-menu.float-down {
        z-index: 10001;
    }

    .float-menu::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-top: 0;
    }

    .float-menu.float-down::before {
        content: "";
        display: block;
        top: -500%;
        left: -500%;
        width: 1100%;
        height: 1100%;
    }

    .float-menu {
        border-radius: 100%; /* circular */
        top: 20%; /* Starting y position */
        left: 0; /* Starting x position */
    }

    .closed {
        width: 50px;
        height: 50px;
    }

    .open {
        width: 150px;
        height: 150px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s ease-in;
    }

    .fade-enter, .fade-leave-to{
        opacity: 0;
    }
</style>